import React, { useState } from 'react';
import { QrReader } from 'react-qr-reader';
import heartIcon from '../assets/images/heart-icon.svg';
import orderartLogo from '../assets/images/orderart-logo.png';
import qrIcon from '../assets/images/qr-icon.svg';
import { wifiIcon } from '../assets/images/_index';
import { isValidUrl } from '../helpers/Utils';

const ScanQR = () => {
  const [isScanning, setIsScanning] = useState(false);

  return (
    <section>
      <div className="pageBgCol">
        <div className="container">
          <div className="row g-0">
            <div className="col-12">
              <div className="pageInner">
                {isScanning ? (
                  <QrReader
                    scanDelay={1500}
                    containerStyle={{
                      maxHeight: '300px',
                      overflow: 'hidden'
                    }}
                    videoStyle={{ width: '100%' }}
                    className="rounded mx-auto px-auto"
                    onResult={(result, err, codeReader) => {
                      const scannedUrl = result?.getText();
                      if (isValidUrl(scannedUrl))
                        window.location.href = `${scannedUrl}`;
                    }}
                    constraints={{ facingMode: 'environment' }}
                  />
                ) : (
                  <>
                    <div className="tapText">
                      <span>Tap your phone</span>
                      <span>
                        <img src={wifiIcon} alt="..." />
                      </span>
                    </div>
                    <div className="orText">
                      <span>or</span>
                    </div>
                    <h1>Scan the QR code to order</h1>
                  </>
                )}
                <button
                  className="btn btnPrimary xlBtn mt-4"
                  onClick={() => setIsScanning(!isScanning)}
                >
                  <span className="btnIcon">
                    <img src={qrIcon} alt="..." />
                  </span>
                  <span>{isScanning ? 'CLOSE' : 'OPEN'} SCANNER</span>
                </button>
              </div>
              <div className="splashBotmCol">
                <ul>
                  <li>
                    <div className="splashBotomText">
                      <span>
                        Made With
                        <span>
                          <img src={heartIcon} alt="..." />
                        </span>
                      </span>
                    </div>
                  </li>
                  <li>
                    <div className="splashBotmLogo">
                      <a href="#!">
                        <img src={orderartLogo} alt="..." />
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ScanQR;
