import React, { useState } from 'react';
import { CloseButton, Form, Offcanvas, Spinner } from 'react-bootstrap';
import { useMutation } from 'react-query';
import { emailIcon, printIcon } from '../assets/images/_index';
import Api from '../helpers/Api';
import { FaPaperPlane, FaSpinner } from 'react-icons/fa';
import ItemDetails from '../components/my-order-details/ItemDetails';

const MyOrderDetails = ({ children, order, refetchOrders }) => {
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState(order?.user?.email);
  const [showEmailInput, setShowEmailInput] = useState(false);
  const EmailOrderMutation = useMutation(
    form => Api.get(`/orders/email-order?order_id=${order?.id}&email=${email}`),
    {
      onSuccess: json => {
        console.log(json);
        // setShowEmailInput(false);
      }
    }
  );

  const SendEmail = e => {
    e.preventDefault();
    if (email) EmailOrderMutation.mutate();
  };

  const PrintOrderMutation = useMutation(
    form => Api.get(`/orders/print-order?order_id=${order?.id}`),
    {
      onSuccess: json => console.log(json)
    }
  );

  const printOrder = e => {
    e.preventDefault();
    if (!order?.is_already_printed) {
      PrintOrderMutation.mutate();
    }
  };
  return (
    <>
      <div onClick={() => setShow(true)}>{children}</div>
      <Offcanvas show={show} placement="start" className="overflow-auto w-100">
        {/* <TopNavbar /> */}
        <div className="singleOrder">
          <div className="container">
            <div className="signleOrderMainCol">
              <div className="lgTitle gradientText bold d-flex justify-content-between">
                <span className="d-print-block">
                  {order?.user?.name}'s order
                </span>
                <CloseButton onClick={() => setShow(false)} />
              </div>
              <div className="orderDetailCol border border-2">
                <div className="orderTableCol">
                  <div className="row g-2 align-items-center">
                    <div className="col">
                      <div className="orderTblTitle">
                        <h4 className="lightColor mb-1">Table</h4>
                      </div>
                    </div>
                    <div className="col-auto">
                      <div className="orderTblTitle">
                        <h4 className="lightColor mb-1">Order</h4>
                      </div>
                    </div>
                  </div>
                  <div className="row g-2 align-items-center">
                    <div className="col">
                      <div className="orderTblTitle">
                        <h4 className="darkColor mb-0">{order?.table?.name}</h4>
                      </div>
                    </div>
                    <div className="col-auto">
                      <div className="orderTblTitle">
                        <h4 className="darkColor mb-0">{order?.order_id}</h4>
                      </div>
                    </div>
                  </div>
                </div>
                {order?.cart_order?.map((item, i) => (
                  <ItemDetails item={item} key={i} />
                ))}
                <div className="orderTableCol">
                  <div className="row g-2 align-items-center">
                    <div className="col-auto">
                      <div className="orderTblTitle">
                        <h4 className="lightColor mb-0">Ordered at:</h4>
                      </div>
                    </div>
                    <div className="col">
                      <div className="orderTblTitle">
                        <h4 className="darkColor mb-0">{order?.created_at}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="orderBtn">
                <ul>
                  <li>
                    <button
                      onClick={printOrder}
                      className={`btn d-print-none iconBtn lgBtn w-100 ${
                        order?.is_already_printed ||
                        PrintOrderMutation.isLoading
                          ? 'btn-secondary disabled'
                          : 'btnDark'
                      }`}
                      disabled={
                        order?.is_already_printed ||
                        PrintOrderMutation.isLoading
                      }
                    >
                      <span>
                        {PrintOrderMutation.isLoading ? (
                          <Spinner size="sm" className="me-2" />
                        ) : (
                          <img src={printIcon} alt="..." />
                        )}
                      </span>{' '}
                      <span>
                        {PrintOrderMutation.isLoading
                          ? 'Printing receipt...'
                          : 'Print Receipt'}
                      </span>
                    </button>
                  </li>
                  <li>
                    <Form onSubmit={SendEmail}>
                      {showEmailInput ? (
                        <div className="d-flex align-items-center justify-content-between">
                          <Form.Control
                            className="mb-2 py-2 d-print-none"
                            placeholder="Enter your email"
                            value={email || ``}
                            type="email"
                            disabled={EmailOrderMutation.isLoading}
                            onChange={e => setEmail(e.target.value)}
                          />
                          <CloseButton
                            type="button"
                            className="mb-2 mx-2"
                            onClick={() => {
                              setEmail('');
                              setShowEmailInput(false);
                            }}
                          />
                        </div>
                      ) : null}
                      <button
                        onClick={() => {
                          if (!showEmailInput) setShowEmailInput(true);
                        }}
                        disabled={EmailOrderMutation.isLoading}
                        type={showEmailInput ? 'submit' : 'button'}
                        className="btn d-print-none btnPrimary iconBtn lgBtn w-100"
                      >
                        <span>
                          {EmailOrderMutation.isLoading ? (
                            <Spinner size="sm" className="me-2" />
                          ) : showEmailInput ? (
                            <FaPaperPlane className="me-2" />
                          ) : (
                            <img src={emailIcon} alt="..." />
                          )}
                        </span>
                        <span>
                          {EmailOrderMutation.isLoading
                            ? 'Sending e-mail...'
                            : showEmailInput
                            ? 'Send mail'
                            : 'E-mail Reciept'}
                        </span>
                      </button>
                    </Form>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Offcanvas>
    </>
  );
};

export default MyOrderDetails;
