import React from 'react';
import { angleLeft, heartWhite } from '../../assets/images/_index';

const Header = ({ closeMenuItem }) => {
  return (
    <div className="pageTopCol headerSection">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-auto">
            <div className="backTriggerCol">
              <button
                onClick={closeMenuItem}
                type="button"
                className="backTrigger"
              >
                <img src={angleLeft} alt="..." />
              </button>
            </div>
          </div>
          <div className="col">
            <div className="pageTitle">Food Details</div>
          </div>
          <div className="col-auto">
            <a href="#!" className="likeLink">
              <img src={heartWhite} alt="..." />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
