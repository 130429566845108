import React, { useContext, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { heartIcon, orderartLogo } from '../assets/images/_index';
import { AppContext } from '../contexts/AppContext';
import Api from '../helpers/Api';
import useUrl from '../hooks/useUrl';

const Login = () => {
  const [username, setUsername] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [nounce, setNounce] = useState('');
  const [otp, setOtp] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [askingForOtpVerification, setAskingForOtpVerification] =
    useState(false);
  const appContext = useContext(AppContext);
  const { updateAppContext } = appContext;
  const navigateDom = useNavigate();
  const url = useUrl();

  const tableId = url.get('table_id');

  useQuery(
    ['validateTable', tableId],
    () => Api.get('/tables/validate?table_id=' + tableId),
    {
      onSuccess: data => {
        if (data) {
          updateAppContext({
            ...appContext,
            tableId: tableId,
            logo: data?.data?.logo,
            tableName: data?.data?.table_name
          });
          localStorage.setItem('tableId', tableId);
          localStorage.setItem('tableName', data?.data?.table_name);
          localStorage.setItem('tableOrderingLogo', data?.data?.logo);
        } else {
          navigateDom('/');
        }
      },
      enabled: !!tableId
    }
  );

  const askForOtpVerification = receivedNounce => {
    setAskingForOtpVerification(true);
    setNounce(receivedNounce);
  };

  const LoginMutation = useMutation(form => Api.post('/users/login', form), {
    onSuccess: json => {
      if (json.data.token) {
        if ('tableOrderingAuthToken' in localStorage) {
          localStorage.removeItem('tableOrderingAuthToken');
          localStorage.removeItem('tableOrderingCartId');
        }
        localStorage.setItem('tableOrderingAuthToken', json.data.token);
        navigateDom('/menu');
      } else if (json.data.nounce) {
        setErrorMessage(json.data.nounce);
      } else {
        setErrorMessage(json.data.otp[0]);
      }
    }
  });

  const RegisterMutation = useMutation(
    form => Api.post('/users/register', form),
    {
      onSuccess: json => {
        const isAlreadyCustomer = json.data.customer;

        isAlreadyCustomer
          ? redirectToMenuByLoggingIn(json.data.nounce)
          : askForOtpVerification(json.data.nounce);
      }
    }
  );

  const redirectToMenuByLoggingIn = nounce => {
    let formData = new FormData();
    formData.append('nounce', nounce);
    formData.append('phone_number', phoneNumber);
    formData.append('table_id', `${tableId}`);

    LoginMutation.mutate(formData);
  };

  const handleSubmit = form => {
    form.preventDefault();
    let formData = new FormData(form.target);

    askingForOtpVerification
      ? LoginMutation.mutate(formData)
      : RegisterMutation.mutate(formData);
  };

  return (
    <section>
      <div className="pageBgCol">
        <div className="container mx-w-mobile">
          <div className="row g-0">
            <div className="col-12">
              <div className="pageInner">
                <div className="titleCol overlayText">
                  <h2>Login</h2>
                </div>
                <form onSubmit={handleSubmit}>
                  <input
                    type="text"
                    name="table_id"
                    value={`${tableId}`}
                    hidden
                    readOnly
                  />
                  <input
                    type="text"
                    name="nounce"
                    value={nounce}
                    readOnly
                    hidden
                  />
                  <div className="formSection">
                    <div className="formCol">
                      <input
                        type="text"
                        className="form-control"
                        name="username"
                        placeholder="Name"
                        value={username}
                        onChange={e => setUsername(e.target.value)}
                        required={!askingForOtpVerification}
                        hidden={askingForOtpVerification}
                      />
                    </div>
                    <div className="formCol">
                      <input
                        type="text"
                        className="form-control"
                        name="phone_number"
                        placeholder="Phone Number"
                        value={phoneNumber}
                        onChange={e => setPhoneNumber(e.target.value)}
                        required={!askingForOtpVerification}
                        hidden={askingForOtpVerification}
                      />
                    </div>
                    <>
                      {askingForOtpVerification ? (
                        <h5>
                          Enter the Verification Code received on your mobile
                          number {phoneNumber}
                        </h5>
                      ) : null}
                    </>
                    <div className="formCol">
                      {errorMessage && (
                        <p className="error"> {errorMessage} </p>
                      )}
                      <input
                        type="text"
                        className="form-control"
                        name="otp"
                        value={otp}
                        onChange={e => setOtp(e.target.value)}
                        placeholder="Verification Code"
                        required={askingForOtpVerification}
                        hidden={!askingForOtpVerification}
                      />
                    </div>
                  </div>
                  <div className="formBtn">
                    <Button
                      type="submit"
                      variant=" btnPrimary"
                      className="btn w-100"
                    >
                      {RegisterMutation.isLoading || LoginMutation.isLoading ? (
                        <Spinner size="sm" className="me-2" />
                      ) : null}
                      {askingForOtpVerification ? 'Verify' : 'Login'}
                    </Button>
                  </div>
                  {errorMessage && (
                    <Button
                      onClick={() => {
                        setAskingForOtpVerification(false);
                        setErrorMessage('');
                        setOtp('');
                      }}
                      variant=" btnPrimary"
                      className="btn mt-3 w-100 py-3"
                    >
                      Enter details again
                    </Button>
                  )}
                </form>
              </div>
              <div className="splashBotmCol">
                <ul>
                  <li>
                    <div className="splashBotomText">
                      <span>
                        Made With
                        <span>
                          <img src={heartIcon} alt="..." />
                        </span>
                      </span>
                    </div>
                  </li>
                  <li>
                    <div className="splashBotmLogo">
                      <a href="#!">
                        <img src={orderartLogo} alt="..." />
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
