import React, { memo } from 'react';
import {
  vegIcon,
  nonVegIcon,
  minus,
  plusIcon
} from '../../assets/images/_index';

const Ingredient = ({
  inputName,
  title,
  price,
  disabled,
  index,
  id,
  vegetarian,
  updateQuantity,
  currentQuantity
}) => {
  const modifyQuantity = (increment = true) => {
    const newQuantity = increment
      ? currentQuantity + 1
      : currentQuantity > 0
      ? currentQuantity - 1
      : 0;

    updateQuantity(index, newQuantity);
  };

  const quantityModifiedTextClass = () => {
    if (inputName === 'default_ingredients') {
      if (currentQuantity === 0) {
        return 'text-danger';
      }
      if (currentQuantity > 1) {
        return 'text-success';
      }
    }

    if (inputName === 'add_on_ingredients') {
      if (currentQuantity > 0) {
        return 'text-success';
      }
    }

    return '';
  };

  return (
    <div className="col-12">
      <div className="row g-3 align-items-center">
        <div className="col">
          <div className="proDetailText">
            <span className="dishSelectIcon">
              <img src={vegetarian ? vegIcon : nonVegIcon} alt="..." />
            </span>
            <span className={quantityModifiedTextClass()}>
              {title} <span className="fwMedium">(+$ {price})</span>
            </span>
          </div>
        </div>
        <div className="col-auto">
          <div className="proDetailQty">
            <div
              className={`quantityField ${disabled ? 'disabled bg-light' : ''}`}
            >
              <button
                type="button"
                disabled={disabled}
                className={`qtyBtn decreaseBtn ${
                  disabled ? 'disabled bg-light' : ''
                }`}
                onClick={() => modifyQuantity(false)}
              >
                <img src={minus} alt="..." />
              </button>
              <div className="qty">{currentQuantity}</div>
              <input
                type="text"
                readOnly
                value={id}
                name={`${inputName}[${index}][id]`}
                hidden
              />
              <input
                type="text"
                readOnly
                value={currentQuantity}
                name={`${inputName}[${index}][qty]`}
                hidden
              />
              <button
                type="button"
                disabled={disabled}
                className={`qtyBtn increaseBtn ${
                  disabled ? 'disabled bg-light' : ''
                }`}
                onClick={() => modifyQuantity()}
              >
                <img src={plusIcon} alt="..." />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Ingredient);
