import React, { useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useQuery } from 'react-query';
import CategoryTabs from '../components/infinite-menu/CategoryTabs';
import SwiperMenu from '../components/infinite-menu/SwiperMenu';
import TopNavbar from '../components/TopNavbar';
import Api from '../helpers/Api';
import useCart from '../hooks/useCart';
import InfiniteScroll from '../components/infinite-menu/InfiniteScroll';

const Menu = () => {
  const [activeCuisineId, setActiveCuisineId] = useState(null);
  const [activeCategoryId, setActiveCategoryId] = useState(null);
  const { Cart } = useCart();

  const { data, refetch, isFetching, isFetched } = useQuery(
    ['dishes'],
    () => Api.get('/dishes/listing'),
    {
      placeholderData: { data: [] },
      staleTime: 1800000
    }
  );

  const categories =
    data?.data
      ?.find(cuisine => +cuisine.cuisine_id === activeCuisineId)
      ?.dish_category.map(category => ({
        id: category.id,
        name: category.name
      })) || [];

  return (
    <section>
      <div className="bg-white sticky-top pt-3">
        <TopNavbar />
        {isFetched && data?.data?.length > 0 && (
          <>
            <SwiperMenu
              activeCuisineId={activeCuisineId}
              data={data?.data}
              categories={categories}
              activeCategoryId={activeCategoryId}
              setActiveCategoryId={setActiveCategoryId}
              setActiveCuisineId={setActiveCuisineId}
            />
            <div className="container">
              <CategoryTabs
                categories={categories}
                setActiveCategoryId={setActiveCategoryId}
                activeCategoryId={activeCategoryId}
              />
            </div>
          </>
        )}
      </div>
      {data?.data?.length > 0 ? (
        <div className="tabSectionMain pt-0 pb-5">
          <div className="container">
            <InfiniteScroll
              data={data?.data}
              activeCuisineId={activeCuisineId}
              setActiveCuisineId={setActiveCuisineId}
              categories={categories}
              setActiveCategoryId={setActiveCategoryId}
            />
          </div>
          {Cart}
        </div>
      ) : (
        <div className="d-flex flex-column justify-content-around align-items-center vh-80">
          <div className="text-center">
            {isFetching ? (
              <div className="text-center py-5">
                <Spinner size="sm" className="me-2" /> Loading...
              </div>
            ) : (
              <>
                <h4 className="fw-bolder">
                  😵‍💫 Some error occured
                  <br /> while fetching dishes!
                </h4>
                <Button className="btnPrimary" onClick={() => refetch()}>
                  Reload Dishes
                </Button>
              </>
            )}
          </div>
        </div>
      )}
    </section>
  );
};
export default Menu;
