import { isArray, upperFirst } from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import {
  Form,
  Offcanvas,
  OverlayTrigger,
  Spinner,
  Tooltip
} from 'react-bootstrap';
import { cartIconWhite, clockIcon } from '../assets/images/_index';
import BaseSelector from '../components/menu-item/BaseSelector';
import Header from '../components/menu-item/Header';
import HeroImage from '../components/menu-item/HeroImage';
import Ingredient from '../components/menu-item/Ingredient';
import IngredientSection from '../components/menu-item/IngredientSection';
import SizeSelector from '../components/menu-item/SizeSelector';
import useQuantitySelector from '../components/menu-item/useQuantitySelector';
import { AppContext } from '../contexts/AppContext';
import { ALLERGENS, DishPrefList } from '../helpers/Constants';
// import NutritionalInfo from '../components/menu-item/NutritionalInfo';

const MenuItem = ({ children, item, AddToCartMutation }) => {
  const [show, setShow] = useState(false);

  // States which are to hold showable data
  const [defaultIngredients, setDefaultIngredients] = useState([]);
  const [addOnIngredients, setAddOnIngredients] = useState([]);
  const [baseOneIngredients, setBaseOneIngredients] = useState([]);
  const [baseTwoIngredients, setBaseTwoIngredients] = useState([]);
  const [baseThreeIngredients, setBaseThreeIngredients] = useState([]);
  const [availableSizes, setAvailableSizes] = useState([]);

  // States which hold modifiable data
  const [basePrice, setBasePrice] = useState(0);
  const [currentItemSize, setCurrentItemSize] = useState(null);
  const [selectedBases, setSelectedBases] = useState({
    base1: null,
    base2: null,
    base3: null
  });

  // Hooks
  const appContext = useContext(AppContext);
  const { QuantityJSX, quantity, resetQuantity } = useQuantitySelector(
    AddToCartMutation.isLoading
  );

  const { cart, tableId } = appContext;

  const details = +item?.is_multi_size_enabled
    ? item?.product_details_with_sizes
    : item?.product_details_without_size;

  const chilli = ['mild', 'medium', 'hot'];

  useEffect(() => {
    const fillDataAndSelectDefaults = details => {
      if (isArray(details)) {
        setAvailableSizes(details);

        const firstSize = details[0];

        setDefaultIngredients(
          processIngredients(firstSize?.default_ingredients, 1)
        );
        setAddOnIngredients(processIngredients(firstSize?.addon_ingredients));
        setBaseOneIngredients(firstSize?.base_one_ingredients);
        setBaseTwoIngredients(firstSize?.base_two_ingredients);
        setBaseThreeIngredients(firstSize?.base_three_ingredients);

        // selecting default values in case of multiple sizes
        setCurrentItemSize(firstSize.id);
        setSelectedBases({
          base1: firstSize?.base_one_ingredients[0]?.ingredient?.id || null,
          base2: firstSize?.base_two_ingredients[0]?.ingredient?.id || null,
          base3: firstSize?.base_three_ingredients[0]?.ingredient?.id || null
        });
      } else {
        setBasePrice(details.size_price);
        setDefaultIngredients(
          processIngredients(details.default_ingredients, 1)
        );
        setAddOnIngredients(processIngredients(details.add_on_ingredients));
        setBaseOneIngredients(details.base_one_ingredients);
        setBaseTwoIngredients(details.base_two_ingredients);
        setBaseThreeIngredients(details.base_three_ingredients);

        // selecting first base as default
        setSelectedBases({
          base1: details?.base_one_ingredients[0]?.ingredient?.id || null,
          base2: details?.base_two_ingredients[0]?.ingredient?.id || null,
          base3: details?.base_three_ingredients[0]?.ingredient?.id || null
        });
      }
    };

    if (currentItemSize) {
      const itemSizeDetails = details?.find(
        size => size.id === currentItemSize
      );
      setBasePrice(itemSizeDetails.price);
      setDefaultIngredients(
        processIngredients(itemSizeDetails.default_ingredients, 1)
      );
      setAddOnIngredients(
        processIngredients(itemSizeDetails.addon_ingredients, 0)
      );
      setBaseOneIngredients(itemSizeDetails.base_one_ingredients);
      setBaseTwoIngredients(itemSizeDetails.base_two_ingredients);
      setBaseThreeIngredients(itemSizeDetails.base_three_ingredients);
    } else {
      fillDataAndSelectDefaults(details);
    }

    return () => {};
  }, [currentItemSize, details]);

  // Functions/Methods:
  const changeSize = useCallback(size => setCurrentItemSize(size), []);

  const closeMenuItem = () => {
    //set every modifiable state to default.
    setCurrentItemSize(null);
    setSelectedBases({
      base1: baseOneIngredients[0]?.ingredient?.id,
      base2: baseTwoIngredients[0]?.ingredient?.id,
      base3: baseThreeIngredients[0]?.ingredient?.id
    });
    resetQuantity();
    setShow(false);
  };

  const totalPrice = () => {
    let totalPrice = 0;

    let defaultIngredientPrice = 0;
    let addOnIngredientPrice = 0;
    let base1Price = 0;
    let base2Price = 0;
    let base3Price = 0;

    defaultIngredientPrice = defaultIngredients.reduce(
      (prevTotal, nextIngredient) => {
        const qty =
          +nextIngredient?.currentQuantity === 0
            ? 1
            : +nextIngredient?.currentQuantity;
        return prevTotal + (qty - 1) * nextIngredient?.price;
      },
      0
    );

    addOnIngredientPrice = addOnIngredients.reduce(
      (prevTotal, nextIngredient) =>
        prevTotal + nextIngredient?.currentQuantity * nextIngredient?.price,
      0
    );

    base1Price =
      baseOneIngredients?.find(
        base => base?.ingredient.id === selectedBases.base1
      )?.price || 0;

    base2Price =
      baseTwoIngredients?.find(
        base => base?.ingredient.id === selectedBases.base2
      )?.price || 0;

    base3Price =
      baseThreeIngredients?.find(
        base => base?.ingredient.id === selectedBases.base3
      )?.price || 0;

    totalPrice =
      +basePrice +
      +defaultIngredientPrice +
      +addOnIngredientPrice +
      +base1Price +
      +base2Price +
      +base3Price;

    return (totalPrice * quantity).toFixed(2);
  };

  const handleSubmit = form => {
    form.preventDefault();
    let formData = new FormData(form.target);
    AddToCartMutation.mutate(formData, {
      onSettled: json => closeMenuItem()
    });
  };

  const processIngredients = (ingredients, defaultQuantity = 0) =>
    ingredients.map(ingredient => ({
      ...ingredient,
      currentQuantity: defaultQuantity
    }));

  const allergens = () => {
    let finalAllergens = [];

    const dishAllergens = item?.allergens?.map(allergen => allergen.value);
    finalAllergens = dishAllergens;

    const getIngAllergens = allergens =>
      allergens.map(allergen => ALLERGENS[allergen]);

    const applyAllergens = (ingredients, type) =>
      ingredients.forEach(ingredient => {
        if (
          (type !== 'ingredient' &&
            ingredient?.ingredient?.id === selectedBases[type]) ||
          (type === 'ingredient' && ingredient?.currentQuantity > 0)
        ) {
          const ingAllergens = ingredient?.ingredient?.nutritional_information
            ? getIngAllergens(
                JSON.parse(ingredient?.ingredient?.nutritional_information)
              )
            : [];

          finalAllergens = finalAllergens.filter(allergen =>
            ingAllergens.includes(allergen)
          );
        }
      });

    applyAllergens(defaultIngredients, 'ingredient');
    applyAllergens(addOnIngredients, 'ingredient');
    applyAllergens(baseOneIngredients, 'base1');
    applyAllergens(baseTwoIngredients, 'base2');
    applyAllergens(baseThreeIngredients, 'base3');

    return finalAllergens;
  };

  const updateDefaultIngredients = useCallback((key, newQuantity) => {
    setDefaultIngredients(oldIngredients =>
      oldIngredients.map((ingredient, index) => {
        if (index === key) {
          ingredient.currentQuantity = newQuantity;
        }
        return ingredient;
      })
    );
  }, []);

  const updateAddOnIngredients = useCallback((key, newQuantity) => {
    setAddOnIngredients(oldIngredients =>
      oldIngredients.map((ingredient, index) => {
        if (index === key) {
          ingredient.currentQuantity = newQuantity;
        }
        return ingredient;
      })
    );
  }, []);

  return (
    <>
      <div onClick={() => setShow(true)}>{children}</div>
      <Offcanvas show={show} placement="end" className="w-100 overflow-auto">
        <Form onSubmit={handleSubmit}>
          <input
            type="text"
            name="product_id"
            value={item?.product_id}
            readOnly
            hidden
          />
          <input
            type="text"
            name="order_id"
            value={cart?.id || undefined}
            readOnly
            hidden
          />
          <input type="text" name="table_id" value={tableId} readOnly hidden />
          <div className="singleProductMain">
            <Header closeMenuItem={closeMenuItem} />
            <div className="singleProCol">
              <div className="container">
                <HeroImage image={item?.product_img} />
                <div className="sProContent">
                  <div className="sProInfo pb-1 d-flex justify-content-between align-items-center">
                    <h4>{item?.product_name}</h4>
                    <div className="pInfoOption mb-2">
                      <span className="pInfoIcon">
                        <img src={clockIcon} alt="..." />
                      </span>
                      <span className="pInfoText">
                        {item?.cooking_time ?? '20 mins'}
                      </span>
                    </div>
                    {/* <MiscellaneousDetails cookingTime={item?.cooking_time} /> */}
                  </div>
                  <div className="proDescriptionCol">
                    <h4 className="mdTitle">Quick Overview</h4>
                    <p>
                      {item?.product_detail}{' '}
                      <a href="#!" className="d-inline-block primaryColor">
                        Read More
                      </a>
                    </p>
                  </div>

                  {currentItemSize ? (
                    <SizeSelector
                      accessor={'title'}
                      items={availableSizes}
                      selectedItem={currentItemSize}
                      update={changeSize}
                      title="Sizes"
                      disabled={AddToCartMutation.isLoading}
                    />
                  ) : null}

                  {item?.is_chilli_enabled ? (
                    <div className="proDetailMainCol">
                      <div className="proDetailTitle">
                        <h4 className="mdTitle lightColor mb-3">Chilli</h4>
                      </div>
                      <div className="proDetailList sizeSelector">
                        {chilli.map(level => (
                          <div className="radio" key={level}>
                            <input
                              id={`chilli_${level}`}
                              name="chilli"
                              type="radio"
                              value={level}
                              defaultChecked={level === 'mild'}
                              disabled={AddToCartMutation.isLoading}
                            />
                            <label
                              htmlFor={`chilli_${level}`}
                              className="radio-label"
                            >
                              {upperFirst(level)}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : null}

                  {defaultIngredients?.length > 0 ? (
                    <div className="proDetailMainCol mb-3">
                      <div className="proDetailTitle">
                        <h4 className="mdTitle lightColor mb-1">
                          Default Ingredients
                        </h4>
                      </div>
                      <div className="proDetailList">
                        <div className="row gy-2">
                          {defaultIngredients.map((ingredient, key) => (
                            <Ingredient
                              disabled={AddToCartMutation.isLoading}
                              inputName="default_ingredients"
                              index={key}
                              key={key}
                              id={ingredient?.ingredient?.id}
                              vegetarian={ingredient?.ingredient?.vegetarian}
                              title={ingredient.ingredient.name}
                              price={ingredient.price}
                              currentQuantity={+ingredient.currentQuantity}
                              updateQuantity={updateDefaultIngredients}
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {addOnIngredients?.length > 0 ? (
                    <>
                      <div className="proDetailMainCol mb-3">
                        <div className="proDetailTitle">
                          <h4 className="mdTitle lightColor mb-1">
                            Add-on Ingredients
                          </h4>
                        </div>
                        <div className="proDetailList">
                          <div className="row gy-2">
                            {addOnIngredients.map((ingredient, key) => (
                              <Ingredient
                                disabled={AddToCartMutation.isLoading}
                                inputName="add_on_ingredients"
                                index={key}
                                key={key}
                                id={ingredient?.ingredient?.id}
                                vegetarian={ingredient?.ingredient?.vegetarian}
                                title={ingredient.ingredient.name}
                                price={ingredient.price}
                                currentQuantity={+ingredient.currentQuantity}
                                updateQuantity={updateAddOnIngredients}
                              />
                            ))}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}

                  {baseOneIngredients?.length > 0 ? (
                    <BaseSelector
                      baseNumber={1}
                      inputName={'base_one_ingredient'}
                      items={baseOneIngredients}
                      selectedItem={selectedBases.base1}
                      update={itemId =>
                        setSelectedBases({ ...selectedBases, base1: itemId })
                      }
                      title={
                        item?.product_details_ingredients_name
                          ?.base_one_ingredient_label || 'Base 1'
                      }
                      disabled={AddToCartMutation.isLoading}
                    />
                  ) : null}

                  {baseTwoIngredients?.length > 0 ? (
                    <BaseSelector
                      baseNumber={2}
                      inputName={'base_two_ingredient'}
                      items={baseTwoIngredients}
                      selectedItem={selectedBases.base2}
                      update={itemId =>
                        setSelectedBases({ ...selectedBases, base2: itemId })
                      }
                      title={
                        item?.product_details_ingredients_name
                          ?.base_two_ingredient_label || 'Base 2'
                      }
                      disabled={AddToCartMutation.isLoading}
                    />
                  ) : null}

                  {baseThreeIngredients?.length > 0 ? (
                    <BaseSelector
                      inputName={'base_three_ingredient'}
                      baseNumber={3}
                      items={baseThreeIngredients}
                      selectedItem={selectedBases.base3}
                      update={itemId =>
                        setSelectedBases({ ...selectedBases, base3: itemId })
                      }
                      title={
                        item?.product_details_ingredients_name
                          ?.base_three_ingredient_label || 'Base 3'
                      }
                      disabled={AddToCartMutation.isLoading}
                    />
                  ) : null}

                  {/* <NutritionalInfo /> */}
                </div>
                <div className="bg-white py-3 sticky-bottom border-top ">
                  <div className="dishPre">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="proPrice">
                        <span className="priceSymbol">$</span> {totalPrice()}
                      </div>
                      <div>{QuantityJSX}</div>
                    </div>
                  </div>
                  <Row>
                    <Col>
                      <div className="proOptionsList pt-2 mt-3 text-center">
                        <ul>
                          {Object.keys(DishPrefList).map(allergen => (
                            <li
                              className={`me-1 ${
                                allergens()?.includes(allergen)
                                  ? 'activeOption'
                                  : ''
                              }`}
                              key={allergen}
                            >
                              <OverlayTrigger
                                placement="auto"
                                overlay={<Tooltip>{allergen}</Tooltip>}
                              >
                                <img alt="..." src={DishPrefList[allergen]} />
                              </OverlayTrigger>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </Col>
                    {/* <Col>{QuantityJSX}</Col> */}
                  </Row>
                  <div className="addCartBtn">
                    <button
                      type="submit"
                      className="btn btnPrimary iconBtn lgBtn w-100"
                    >
                      {AddToCartMutation.isLoading ? (
                        <div className="d-flex align-items-center justify-content-center">
                          <Spinner size="sm" className="me-2" />
                          Adding...
                        </div>
                      ) : (
                        <>
                          <span>
                            <img src={cartIconWhite} alt="..." />
                          </span>{' '}
                          <span>Add to Cart ($ {totalPrice()})</span>
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </Offcanvas>
    </>
  );
};

export default MenuItem;
