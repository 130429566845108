import { ApiConfig } from '../configs/api';
import { toast } from 'react-toastify';

const Api = {
  get: async (url, showToast = true, headers = {}) => {
    const response = await fetch(getUrl(url), {
      method: 'GET',
      headers: getHeaders(headers)
    })
      .then(response => manageResponse(showToast, response))
      .catch(error => manageError(error));
    return response;
  },
  post: async (
    url,
    body,
    showToast = true,
    showFormErrorToasts = true,
    headers = {}
  ) => {
    const response = await fetch(getUrl(url), {
      method: 'POST',
      headers: getHeaders(headers),
      body: body
    }).then(response =>
      manageResponse(showToast, response, showFormErrorToasts)
    );
    return response;
  },
  put: async (
    /** @type {string} */ url,
    body = new FormData(),
    showToast = true,
    showFormErrorToasts = true,
    headers = {}
  ) => {
    body.append('_method', 'PUT');
    const response = await fetch(getUrl(url), {
      method: 'POST',
      headers: getHeaders(headers),
      body: body
    }).then(response =>
      manageResponse(showToast, response, showFormErrorToasts)
    );
    return response;
  },
  patch: async (url, body, showToast = true, headers = {}) => {
    const response = await fetch(getUrl(url), {
      method: 'PATCH',
      headers: getHeaders(headers),
      body: body
    })
      .then(response => manageResponse(showToast, response))
      .catch(error => manageError(error));
    return response;
  },
  delete: async (url, showToast = true, headers = {}) => {
    const response = await fetch(getUrl(url), {
      method: 'DELETE',
      headers: getHeaders(headers)
    })
      .then(response => manageResponse(showToast, response))
      .catch(error => manageError(error));
    return response;
  }
};

const getHeaders = newHeaders => {
  let headers = { accept: 'application/json' };

  if (localStorage.getItem('tableOrderingAuthToken')) {
    headers['Authorization'] =
      'Bearer ' + localStorage.getItem('tableOrderingAuthToken');
  }

  if (newHeaders) {
    for (const property in newHeaders) {
      if (Object.hasOwnProperty.call(newHeaders, property)) {
        headers[property] = newHeaders[property];
      }
    }
  }
  return headers;
};

const getUrl = url => ApiConfig.baseUrl + url;

const manageError = (error, response) => {
  console.log(
    '%c Error in API! ',
    'background: #222; color: #bada55',
    error,
    response
  );
  toast.error('error in api');
};

const manageResponse = (showToast, response, showFormErrorToasts = true) => {
  // INFO: Redirecting on UnAuthorized.
  if (response.status === 401) {
    localStorage.removeItem('tableOrderingAuthToken');
    window.location.href = '/';
  }

  if (response.status === 404) {
    toast.error(`${response.url} Not Found`, { autoClose: 5000 });
  }

  // INFO: Catching Server Error
  if (response.status === 500) {
    response
      .json()
      .then(responseData =>
        toast.error(responseData.message, { autoClose: 5000 })
      );
    return;
  }

  const responseData = response.json();
  // Danger: Catching user errors
  if (response.status === 422) {
    // Returning the promise along with the status code.
    return responseData.then(json => {
      // INFO: Checking if Form errors found.
      if (json?.errors) {
        if (showFormErrorToasts) {
          for (const field in json?.errors) {
            if (Object.hasOwnProperty.call(json?.errors, field)) {
              json?.errors[field].forEach(error => {
                toast.warn(error);
              });
            }
          }
        }
      } else {
        // HACK: If direct message from SQL
        if (json?.message) {
          return console.error(json?.message);
        }

        // HACK: different error format, if found
        for (const field in json) {
          if (Object.hasOwnProperty.call(json, field)) {
            json[field].forEach(error => {
              toast.warn(error);
            });
          }
        }
      }
      return { ...json, status: response.status };
    });
  }

  responseData.then(json => {
    if (response.status === 403) {
      toast.warn(json?.message);
    }
    if (showToast && json?.message && response.status !== 403) {
      if (response.status > 399 && response.status < 500) {
        toast.warn(json?.message, { autoClose: 5000 });
      }

      if (response.status > 199 && response.status < 300) {
        console.log(`%c ${json?.message}`, 'background: #bada55; color: #222');
      }
    }
  });

  if (response.status === 403) {
    return responseData.then(json => ({ ...json, status: response.status }));
  }

  if (response.status > 399 && response.status < 500) {
    return;
  }

  // returning the promise along the with the status code
  return responseData.then(json => ({ ...json, status: response.status }));
};

export default Api;
