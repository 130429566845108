import React, { useContext, useState } from 'react';
import { Col, Offcanvas, Row } from 'react-bootstrap';
import { useMutation, useQuery } from 'react-query';
import { backArrow, cartIcon } from '../assets/images/_index';
import { AppContext } from '../contexts/AppContext';
import Api from '../helpers/Api';
import { isValidUrl } from '../helpers/Utils';
import CartItem from '../components/cart/CartItem';
import { Spinner } from 'react-bootstrap';

const useCart = () => {
  const appState = useContext(AppContext);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const cartId = appState.cart?.id;

  const { data, refetch } = useQuery(
    'cart_items',
    () => {
      return Api.get('/carts/cart-items?id=' + cartId, false);
    },
    {
      onSuccess: json => {
        if (json?.data?.items?.length === 0) setShow(false);
      },
      enabled: !!cartId
    }
  );

  const ProceedToPayMutation = useMutation(
    () => Api.get('/orders/payment?order_id=' + appState.cart.id),
    {
      onSuccess: json => {
        if (isValidUrl(json?.url)) {
          window.location.href = json?.url;
        }
      }
    }
  );

  const Cart = (
    <>
      <div
        className="floatingCart text-black cursor-pointer"
        onClick={() => setShow(true)}
      >
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <div className="cartPrice">
              <span>
                <img src={cartIcon} alt="..." />
              </span>{' '}
              <span>$ {data?.data?.net_total || 0} </span>
            </div>
          </div>
          <div>
            <span className="cartItemsCountCol">
              View Cart ({data?.data?.items?.length || 0} items)
            </span>
          </div>
        </div>
      </div>
      <Offcanvas
        placement="bottom"
        className="btmCanvasStyle"
        show={show}
        onHide={handleClose}
      >
        <Offcanvas.Header>
          <Row className="align-items-center w-100">
            <Col xs="auto">
              <button onClick={handleClose} className="offCanvasBackBtn">
                <img src={backArrow} alt="" />
              </button>
            </Col>
            <Col>
              <Offcanvas.Title>Cart</Offcanvas.Title>
            </Col>
          </Row>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="cartItemsCol">
            {data?.data?.items?.map((item, key) => (
              <CartItem refetchCartItems={refetch} item={item} key={key} />
            ))}
            <div className="p2pCol">
              <div className="row align-items-center g-2">
                <div className="col">
                  <button
                    onClick={() => {
                      ProceedToPayMutation.mutate();
                    }}
                    disabled={
                      ProceedToPayMutation.isLoading ||
                      (!appState.cart.id && data?.data?.items?.length === 0)
                    }
                    className={`btn cursor-pointer payBtn ${
                      appState.cart.id && data?.data?.items?.length > 0
                        ? 'btnPrimary'
                        : 'btn-danger disabled'
                    }`}
                  >
                    <>
                      {ProceedToPayMutation.isLoading
                        ? 'Proceeding...'
                        : 'Proceed'}
                      <span className="d-block mt-1">
                        To Pay
                        {ProceedToPayMutation.isLoading ? (
                          <Spinner size="sm" className="ms-2" />
                        ) : null}
                      </span>
                    </>
                  </button>
                </div>
                <div className="col-auto">
                  <span className="priceLgText gradientText">
                    ${data?.data?.net_total || 0}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
  return {
    refetch,
    Cart,
    addedItems: data?.data?.items || []
  };
};

export default useCart;
