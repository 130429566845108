import React from 'react';
import {
  acceptedCards,
  angleRightWhite,
  backArrow,
  img1
} from '../assets/images/_index';

const Payment = () => {
  return (
    <section>
      <div className="pageTopCol headerSection">
        <div className="container">
          <div className="row g-3 align-items-center">
            <div className="col-auto">
              <div className="backTriggerCol">
                <a href="ordering-screen.html">
                  <img src={backArrow} alt="..." className="d-block" />
                </a>
              </div>
            </div>
            <div className="col">
              <div className="pageTitle">Add More items</div>
            </div>
          </div>
        </div>
      </div>
      <div className="paymentContent">
        <div className="container">
          <form action="" className="formStyle">
            <div className="pOptions">
              <div className="paymentProOption">
                <div className="row g-2">
                  <div className="col-auto">
                    <div className="smProImgCol">
                      <img src={img1} alt="..." className="sProImg" />
                    </div>
                  </div>
                  <div className="col">
                    <div className="row">
                      <div className="col">
                        <span className="pProTitle">
                          Garlic & Cheese Focaccia
                        </span>
                      </div>
                      <div className="col-auto">
                        <span className="pPrice">$565.99</span>
                      </div>
                    </div>
                    <div className="pProBtmRow">
                      <div className="row align-items-center g-2">
                        <div className="col">
                          <div className="qtyCol2">
                            <div className="row align-items-center g-2">
                              <div className="col-auto">Qty</div>
                              <div className="col">
                                <select className="form-select qtySelect">
                                  <option value="1">1</option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-auto">$565.99 for each</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="paymentProOption">
                <div className="row g-2">
                  <div className="col-auto">
                    <div className="smProImgCol">
                      <img src={img1} alt="..." className="sProImg" />
                    </div>
                  </div>
                  <div className="col">
                    <div className="row">
                      <div className="col">
                        <span className="pProTitle">
                          Garlic & Cheese Focaccia
                        </span>
                      </div>
                      <div className="col-auto">
                        <span className="pPrice">$565.99</span>
                      </div>
                    </div>
                    <div className="pProBtmRow">
                      <div className="row align-items-center g-2">
                        <div className="col">
                          <div className="qtyCol2">
                            <div className="row align-items-center g-2">
                              <div className="col-auto">Qty</div>
                              <div className="col">
                                <select className="form-select qtySelect">
                                  <option value="1">1</option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-auto">$565.99 for each</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="paymentProOption">
                <div className="row g-2">
                  <div className="col-auto">
                    <div className="smProImgCol">
                      <img src={img1} alt="..." className="sProImg" />
                    </div>
                  </div>
                  <div className="col">
                    <div className="row">
                      <div className="col">
                        <span className="pProTitle">
                          Garlic & Cheese Focaccia
                        </span>
                      </div>
                      <div className="col-auto">
                        <span className="pPrice">$565.99</span>
                      </div>
                    </div>
                    <div className="pProBtmRow">
                      <div className="row align-items-center g-2">
                        <div className="col">
                          <div className="qtyCol2">
                            <div className="row align-items-center g-2">
                              <div className="col-auto">Qty</div>
                              <div className="col">
                                <select className="form-select qtySelect">
                                  <option value="1">1</option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-auto">$565.99 for each</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="totalColMain">
              <div className="row">
                <div className="col">
                  <span className="sBold">Subtotal</span>
                </div>
                <div className="col-auto">
                  <span className="bold">$1665.99</span>
                </div>
              </div>
              <hr />
              <div className="promoCode">
                <input
                  type="text"
                  placeholder="Add promotional code"
                  className="form-control"
                />
              </div>
              <div className="totalCol">
                <div className="row">
                  <div className="col">
                    <span className="sBold">Total due</span>
                  </div>
                  <div className="col-auto">
                    <span className="bold">$1665.99</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-2">
              <div className="col-12">
                <label htmlFor="cardFld" className="formLbl">
                  Card Information
                </label>
                <div className="cardFldCol">
                  <input
                    type="text"
                    className="form-control cardFld"
                    id="cardFld"
                    placeholder="9800 6565 10101 1377"
                  />
                  <img src={acceptedCards} alt="..." className="aCardImg" />
                </div>
              </div>
              <div className="col-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="mm/yy"
                />
              </div>
              <div className="col-6">
                <input type="text" className="form-control" placeholder="CVC" />
              </div>
              <div className="col-12">
                <label htmlFor="cardNameFld" className="formLbl">
                  Name on card
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="cardNameFld"
                  placeholder="Brooklyn Simmons"
                />
              </div>
              <div className="col-12">
                <label htmlFor="countryFld" className="formLbl">
                  Country or region
                </label>
                <select className="form-select formSelect">
                  <option selected>United States</option>
                  <option value="">Option 2</option>
                  <option value="">Option 3</option>
                  <option value="">Option 4</option>
                </select>
              </div>
              <div className="col-12">
                <input type="text" className="form-control" placeholder="ZIP" />
              </div>
              <div className="col-12">
                <div className="payBtn pt-2">
                  <button className="btn iconBtn lgBtn btnPrimary">
                    <span>Pay</span>{' '}
                    <span>
                      <img
                        src={angleRightWhite}
                        alt="..."
                        className="pt-1 ps-1"
                      />
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Payment;
