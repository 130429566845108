import React, { useContext, useState } from 'react';
import { Collapse, Form, Spinner } from 'react-bootstrap';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import { useMutation, useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import {
  closeTriggerDark,
  emailIcon2,
  sendEmailIcon
} from '../assets/images/_index';
import Api from '../helpers/Api';
import useUrl from '../hooks/useUrl';
import { AppContext } from '../contexts/AppContext';
import { queryClient } from '../App';

const Item = ({ item }) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <tr>
        <td className="pe-0" onClick={() => setShow(!show)}>
          {item?.product_detail ? (
            show ? (
              <FaChevronUp className="cursor-pointer" />
            ) : (
              <FaChevronDown className="cursor-pointer" />
            )
          ) : null}
        </td>
        <td>{item?.product?.itemname} </td>
        <td className="text-center">{item?.quantity}</td>
        <td>${+item?.totalprice}</td>
      </tr>
      {item?.product_detail ? (
        <tr>
          <Collapse in={show}>
            <td colSpan={4}>
              <div
                dangerouslySetInnerHTML={{
                  __html: item?.product_detail
                }}
              ></div>
            </td>
          </Collapse>
        </tr>
      ) : null}
    </>
  );
};

const PaymentSuccessful = () => {
  const url = useUrl();
  const orderId = url.get('order_id');
  const [email, setEmail] = useState('');
  const { data, isLoading } = useQuery(
    'payment-details',
    () => Api.get('/orders/order/' + orderId),
    {
      enabled: !!orderId
    }
  );
  const appState = useContext(AppContext);
  const { updateAppContext } = appState;
  const EmailOrderMutation = useMutation(
    form => Api.get(`/orders/email-order?order_id=${orderId}&email=${email}`),
    {
      onSuccess: json => console.log(json)
    }
  );
  const SendEmail = e => {
    e.preventDefault();
    if (email) EmailOrderMutation.mutate();
  };

  return (
    <section>
      <div className="pageTopCol headerSection">
        <div className="container">
          <div className="row g-3 align-items-center">
            <div className="col-auto">
              <div className="backTriggerCol">
                <Link
                  to="/"
                  onClick={() => {
                    updateAppContext({ ...appState, cart: { id: null } });
                    localStorage.removeItem('tableOrderingCartId');
                    queryClient.removeQueries(['cart_items'], { exact: true });
                  }}
                >
                  <img
                    src={closeTriggerDark}
                    alt="..."
                    className="d-block"
                    width="16"
                  />
                </Link>
              </div>
            </div>
            <div className="col">
              <div className="pageTitle bold">
                Table: {isLoading ? '...' : data?.data?.table?.name} -{' '}
                {isLoading ? '...' : data?.data?.restaurant_name}
              </div>
              <span className="dateTime d-block lh-1">
                {isLoading ? '...' : data?.data?.order_created_at}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="paymentContent">
        <div className="container">
          {/* <div className="simpleCard">
            <div className="row align-items-center">
              <div className="col">
                <div className="mdTitle">Get venue promotions</div>
                <p className="lightColor">
                  Keep up with the latest promos at Michelangelo’s?{' '}
                  <a href="#!" className="bold darkColor inline-block">
                    Tap Here
                  </a>
                </p>
              </div>
              <div className="col-auto">
                <img src={giftIcon} alt="..." />
              </div>
            </div>
          </div> */}
          <div className="formCardPayment">
            <label className="pb-0">Request Email Receipt</label>
            <Form onSubmit={SendEmail} className="mt-1">
              <div className="emailFormCol">
                <span className="emailIcon">
                  <img src={emailIcon2} alt="..." />
                </span>
                <input
                  type="email"
                  disabled={EmailOrderMutation.isLoading}
                  className="form-control mdTitle"
                  placeholder="Type Email..."
                  value={email}
                  required
                  onChange={e => setEmail(e.target.value)}
                />
                <button
                  type="submit"
                  disabled={EmailOrderMutation.isLoading}
                  className="sendEmail bg-transparent border-0"
                >
                  <span>
                    {EmailOrderMutation.isLoading ? (
                      <Spinner size="sm" />
                    ) : (
                      <img src={sendEmailIcon} alt="..." />
                    )}
                  </span>
                  <span>Send</span>
                </button>
              </div>
            </Form>
          </div>
          <div className="paidColMain">
            <div className="  lgTitle gradientText bold">
              You Paid ${isLoading ? '...' : data?.data?.final_amount || 0}
            </div>
            {/* <a href="#!" className="lightColor sBold">
              Show details
            </a> */}
            <div className="simpleCard mt-3">
              <h4 className="mdTitle mb-3">My Order</h4>
              <div className="table-responsive">
                <table className="table mb-0 lastAlignRight sBold orderTable lh-sm">
                  <thead>
                    <tr className="">
                      <th></th>
                      <th>Item</th>
                      <th className="text-center">Quantity</th>
                      <th className="text-end">Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                      <tr>
                        <td colSpan={3} className="text-center">
                          <Spinner size="sm" /> Loading...
                        </td>
                      </tr>
                    ) : (
                      data?.data?.cartOrder?.map((item, i) => (
                        <Item item={item} key={i} />
                      ))
                    )}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td></td>
                      <td></td>
                      <td className="text-center fw-bolder">Total:</td>
                      <td>${data?.data?.final_amount || 0}</td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PaymentSuccessful;
