import React, { useContext, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { queryClient } from '../../App';
import { confirmationIcon } from '../../assets/images/_index';
import { AppContext } from '../../contexts/AppContext';

const ChooseOrderStateModal = ({ children }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const appState = useContext(AppContext);
  const { updateAppContext, cart } = appState;
  const navigate = useNavigate();

  const handleShow = () => {
    if (cart.id) {
      setShow(true);
    } else {
      navigate('/menu');
    }
  };

  const navigateToMenu = clearPreviousOrder => {
    if (clearPreviousOrder) {
      updateAppContext({ ...appState, cart: { id: null } });
      localStorage.removeItem('tableOrderingCartId');
      queryClient.removeQueries(['cart_items'], { exact: true });
    }
    navigate('/menu');
  };
  return (
    <>
      <div className="cursor-pointer" onClick={handleShow}>
        {children}
      </div>

      <Modal
        className="confirmationMdl"
        show={show}
        centered
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Choose Order</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <div className="confirmationCol">
            <img src={confirmationIcon} alt="..." />
            <h6 className="mt-3">
              Currently you have on-going order, What would you like to do?
            </h6>
          </div>
        </Modal.Body>
        <Modal.Footer className="p-0 d-block">
          <div className="row g-0 m-0">
            <div className="col-6">
              <div className="mdlBtn">
                <button
                  type="button"
                  className="btn btnOutline"
                  onClick={() => navigateToMenu(false)}
                >
                  Continue Order
                </button>
              </div>
            </div>
            <div className="col-6">
              <div className="mdlBtn">
                <button
                  type="button"
                  className="btn btnPrimary"
                  onClick={() => navigateToMenu(true)}
                >
                  Start New Order
                </button>
              </div>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ChooseOrderStateModal;
