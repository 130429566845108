import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { confirmationIcon } from '../../assets/images/_index';

const useRemoveItemConfirmationModal = RemoveItemMutation => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const showConfirmationModal = () => {
    setShow(true);
  };

  const RemoveItemConfirmationModal = () => (
    <>
      <Modal
        className="confirmationMdl"
        show={show}
        centered
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="confirmationCol">
            <img src={confirmationIcon} alt="..." />
            <h3>Are you sure?</h3>
            <p>The product will be removed</p>
          </div>
        </Modal.Body>
        <Modal.Footer className="p-0 d-block">
          <div className="row g-0 m-0">
            <div className="col-6">
              <div className="mdlBtn">
                <button
                  type="button"
                  className="btn btnOutline"
                  onClick={handleClose}
                >
                  Cancel
                </button>
              </div>
            </div>
            <div className="col-6">
              <div className="mdlBtn">
                <button
                  type="button"
                  className="btn btnPrimary"
                  onClick={() => {
                    RemoveItemMutation.mutate();
                    handleClose();
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );

  return { RemoveItemConfirmationModal, showConfirmationModal };
};

export default useRemoveItemConfirmationModal;
