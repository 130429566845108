import React, { createContext, useState } from 'react';
import { michelangeloLogo } from '../assets/images/_index';

const defaultAppContext = {
  cart: {
    id: localStorage.getItem('tableOrderingCartId') || null
  },
  tableId: localStorage.getItem('tableId') || null,
  tableName: localStorage.getItem('tableName') || null,
  logo: localStorage.getItem('tableOrderingLogo') || michelangeloLogo,
  updateAppContext: newAppContext => {}
};

export const AppContext = createContext(defaultAppContext);

const AppProvider = ({ children }) => {
  const [appState, setAppState] = useState({
    ...defaultAppContext,
    updateAppContext: value => setAppState(value)
  });

  return <AppContext.Provider value={appState}>{children}</AppContext.Provider>;
};

export default AppProvider;
