import React from 'react';
import { Collapse, Nav } from 'react-bootstrap';

const CategoryTabs = ({
  categories,
  setActiveCategoryId,
  activeCategoryId
}) => {
  return (
    <Collapse in={categories.length > 0}>
      {categories.length > 0 ? (
        <Nav variant="tabs">
          {categories.map(category => (
            <Nav.Item data-category-id={category.id} key={category.id}>
              <Nav.Link
                active={+category.id === activeCategoryId}
                className={
                  +category.id === activeCategoryId
                    ? 'text-danger'
                    : 'text-dark'
                }
                key={category.id}
                onClick={() => setActiveCategoryId(+category.id)}
                href={`#category_${category.id}`}
              >
                {category.name}
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
      ) : (
        <></>
      )}
    </Collapse>
  );
};

export default CategoryTabs;
