import React, { useState } from 'react';
import { minus, plusIcon } from '../../assets/images/_index';

const useQuantitySelector = disabled => {
  const [quantity, setQuantity] = useState(1);
  const increment = () => setQuantity(quantity + 1);
  const decrement = () => setQuantity(quantity > 1 ? quantity - 1 : 1);
  const resetQuantity = () => setQuantity(1);
  const QuantityJSX = (
    <div className="proDetailQty">
      <div className={`quantityField ${disabled ? 'bg-light' : ''}`}>
        <button
          type="button"
          disabled={disabled}
          className={`qtyBtn decreaseBtn ${
            disabled ? 'disabled bg-light' : ''
          }`}
          onClick={decrement}
        >
          <img src={minus} alt="..." />
        </button>
        <div className="qty">{quantity}</div>
        <input type="text" name="quantity" hidden value={quantity} readOnly />
        <button
          type="button"
          disabled={disabled}
          className={`qtyBtn increaseBtn ${
            disabled ? 'disabled bg-light' : ''
          }`}
          onClick={increment}
        >
          <img src={plusIcon} alt="..." />
        </button>
      </div>
    </div>
  );
  return { quantity, QuantityJSX, resetQuantity };
};

export default useQuantitySelector;
