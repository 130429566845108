import {
  dfVegetarian,
  dfGlutenFree,
  dfDairyFree,
  dfNutFree,
  dfVegan
} from '../assets/images/_index';

export const DishPrefList = {
  Vegetarian: dfVegetarian,
  'Gluten Free': dfGlutenFree,
  'Dairy Free': dfDairyFree,
  'Nut Free': dfNutFree,
  Vegan: dfVegan
};

export const ALLERGENS = {
  1: 'Vegetarian',
  2: 'Gluten Free',
  3: 'Dairy Free',
  4: 'Nut Free',
  5: 'Vegan'
};
