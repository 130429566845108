import React from 'react';
import Homepage from '../pages/Homepage';
import Login from '../pages/Login';
import Menu from '../pages/Menu';
import MyOrders from '../pages/MyOrders';
import Payment from '../pages/Payment';
import PaymentFailed from '../pages/PaymentFailed';
import PaymentSuccessful from '../pages/PaymentSuccessful';

const routes = [
  {
    path: '/',
    element: <Homepage />,
    errorElement: <div>Page not found!</div>
  },
  {
    path: 'menu',
    element: <Menu />
  },
  {
    path: 'login',
    element: <Login />
  },
  {
    path: 'payment',
    element: <Payment />
  },
  {
    path: 'payment-successful',
    element: <PaymentSuccessful />
  },
  {
    path: 'my-orders',
    element: <MyOrders />
  },
  {
    path: 'payment-failed',
    element: <PaymentFailed />
  }
];

export default routes;
