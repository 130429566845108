import React, { useState } from 'react';
import { Collapse } from 'react-bootstrap';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import { img1 } from '../../assets/images/_index';

const ItemDetails = ({ item }) => {
  const [detailsOpen, setDetailsOpen] = useState(false);
  const toggleDetails = () => setDetailsOpen(!detailsOpen);

  return (
    <div className="orderTableCol">
      <div className="row">
        <div className="col-auto">
          <div className="smProImgCol cursor-pointer">
            <img
              src={item?.product_thumbnail || img1}
              alt="..."
              className="sProImg"
            />
          </div>
        </div>
        <div className="col">
          <div className="d-flex justify-content-between align-items-center">
            <div className="pProTitle">
              {item?.product_name}{' '}
              {item?.product_size ? `(${item?.product_size})` : null}
            </div>
            {item?.product_detail ? (
              <button
                className="cursor-pointer bg-transparent border-0"
                onClick={toggleDetails}
              >
                {detailsOpen ? <FaChevronUp /> : <FaChevronDown />}
              </button>
            ) : null}
          </div>
          <div className="mt-1 d-flex justify-content-between">
            <span>
              Qty: <b>{item?.quantity}</b>
            </span>
            <span>
              $ <b>{item?.total_price}</b>
            </span>
          </div>
        </div>
      </div>
      <Collapse in={detailsOpen}>
        {item?.product_detail ? (
          <div className="row g-2 mt-2 align-items-center">
            <div className="col">
              <div className="orderTblTitle">
                <h4 className="lightColor mb-1">Details:</h4>
              </div>
              <div className="ingradientsCard">
                <ul>
                  {item?.ingredients?.map(ingredient => {
                    const defaultRemoved =
                      ingredient?.type === 'default_ingredient'
                        ? +ingredient?.quantity === 0
                        : false;
                    const quantity =
                      ingredient?.type === 'default_ingredient' &&
                      +ingredient?.quantity === 0
                        ? +ingredient?.quantity + 1
                        : +ingredient?.quantity;
                    return (
                      <li
                        className={`mb-0 ${
                          defaultRemoved
                            ? 'secondaryBorderColor'
                            : 'primaryBorderColor'
                        }`}
                      >
                        <span>
                          {defaultRemoved ? '-' : '+'}
                          {quantity} {ingredient?.name}
                        </span>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: item?.product_detail
                }}
              ></div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </Collapse>
    </div>
  );
};

export default ItemDetails;
