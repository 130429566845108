import React from 'react';

const BaseSelector = ({
  items,
  inputName,
  title,
  selectedItem,
  update,
  disabled,
  baseNumber
}) => {
  const Item = ({ item }) => {
    const { ingredient } = item;
    const selected = selectedItem === ingredient?.id;
    const controlId = `base-radio-${baseNumber}-size-${ingredient?.id}`;
    return (
      <div className="radio">
        <input
          id={controlId}
          value={ingredient?.id}
          checked={selected}
          type="radio"
          name={inputName}
          onChange={e => {
            update(+e.target.value);
          }}
          disabled={disabled}
        />
        <label htmlFor={controlId} className={`radio-label`}>
          {ingredient?.name} ($ {item?.price})
        </label>
      </div>
    );
  };

  return (
    <div className="proDetailMainCol mt-3">
      <div className="proDetailTitle">
        <h4 className="mdTitle lightColor mb-3">{title}</h4>
      </div>
      <div className="proDetailList sizeSelector">
        {items.map((item, key) => (
          <Item item={item} key={key} />
        ))}
      </div>
    </div>
  );
};

export default BaseSelector;
