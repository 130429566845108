import React from 'react';
import { Spinner } from 'react-bootstrap';
import { useMutation } from 'react-query';
import { deleteIcon, minus, plusIcon } from '../../assets/images/_index';
import Api from '../../helpers/Api';
import useRemoveItemConfirmationModal from './RemoveItemConfirmationModal';
import { FaPepperHot } from 'react-icons/fa';
import { upperFirst } from 'lodash';

const CartItem = ({ item, refetchCartItems }) => {
  const MutationActions = {
    onSuccess: json => refetchCartItems()
  };

  const RemoveItemMutation = useMutation(
    () => Api.get('/carts/delete-item?cart_id=' + item?.cart_id),
    { ...MutationActions }
  );

  const incrementQuantity = useMutation(
    formdata =>
      Api.get(
        '/carts/quantity-update?quantity=increment&cart_id=' + item?.cart_id
      ),
    { ...MutationActions }
  );

  const decrementQuantity = useMutation(
    formdata =>
      Api.get(
        '/carts/quantity-update?quantity=decrement&cart_id=' + item?.cart_id
      ),
    { ...MutationActions }
  );

  const { RemoveItemConfirmationModal, showConfirmationModal } =
    useRemoveItemConfirmationModal(RemoveItemMutation);

  const decrementOrRemoveItem = () => {
    if (item?.item_quantity > 1) {
      decrementQuantity.mutate();
    } else {
      showConfirmationModal();
    }
  };

  return (
    <div className="cartItem">
      <RemoveItemConfirmationModal />
      <div className="row g-2 align-items-center">
        <div className="col-auto">
          <div className="cartProImgCol">
            <img src={item?.first_image_url} alt="..." />
          </div>
        </div>
        <div className="col">
          <div className="cartContentCol">
            <div className="row gx-2 align-items-center">
              <div className="col">
                <span className="cartProtitle">{item?.item_name}</span>
              </div>
              <div className="col-auto">
                <button
                  className={`cartItemRemoveTrigger ${
                    incrementQuantity.isLoading ||
                    decrementQuantity.isLoading ||
                    RemoveItemMutation.isLoading
                      ? ''
                      : 'bg-white'
                  }`}
                  onClick={showConfirmationModal}
                >
                  {RemoveItemMutation.isLoading ? (
                    <Spinner size="sm" />
                  ) : (
                    <img src={deleteIcon} alt="..." />
                  )}
                </button>
              </div>
              <div className="col-12">
                <span className="qpText">
                  <div className="d-flex justify-content-between">
                    <div>Size: {item?.selected_size || 'Standard'}</div>
                    <div>Price: ${item?.per_item_price}</div>
                    <div>
                      {item?.chilli ? (
                        <>
                          <FaPepperHot className="text-danger" />:{' '}
                          {upperFirst(item?.chilli)}
                        </>
                      ) : null}
                    </div>
                  </div>
                </span>
              </div>
            </div>
            <div className="qtyPriceCol">
              <div className="row g-2 align-items-center">
                <div className="col">
                  <div className="quantityField ">
                    <button
                      className={`qtyBtn decreaseBtn ${
                        incrementQuantity.isLoading ||
                        decrementQuantity.isLoading ||
                        RemoveItemMutation.isLoading
                          ? 'bg-secondary bg-opacity-25'
                          : ''
                      }`}
                      onClick={decrementOrRemoveItem}
                      disabled={
                        decrementQuantity.isLoading ||
                        RemoveItemMutation.isLoading
                      }
                    >
                      {decrementQuantity.isLoading ? (
                        <Spinner size="sm" />
                      ) : (
                        <img src={minus} alt="..." />
                      )}
                    </button>
                    <div className="qty fw-bolder">{item?.item_quantity}</div>
                    <button
                      className={`qtyBtn increaseBtn ${
                        incrementQuantity.isLoading ||
                        decrementQuantity.isLoading ||
                        RemoveItemMutation.isLoading
                          ? 'bg-secondary bg-opacity-25'
                          : ''
                      }`}
                      onClick={() => incrementQuantity.mutate()}
                      disabled={
                        incrementQuantity.isLoading ||
                        RemoveItemMutation.isLoading
                      }
                    >
                      {incrementQuantity.isLoading ? (
                        <Spinner size="sm" />
                      ) : (
                        <img src={plusIcon} alt="..." />
                      )}
                    </button>
                  </div>
                </div>
                <div className="col-auto">
                  <span className="cartPriceCol">$ {item?.total_price}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="my-2"
        dangerouslySetInnerHTML={{ __html: item?.ingredients }}
      />
    </div>
  );
};

export default CartItem;
