import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { angleLeft, tableIcon } from '../assets/images/_index';
import { AppContext } from '../contexts/AppContext';

const TopNavbar = () => {
  const { pathname } = useLocation();
  const appState = useContext(AppContext);
  const { logo, tableName } = appState;

  return (
    <div className="container">
      <div className="row g-3 align-items-center">
        <div className="col-auto">
          <div className="backTriggerCol">
            {pathname !== '/' ? (
              <Link to="/" className="backTrigger">
                <img src={angleLeft} alt="..." />
              </Link>
            ) : null}
          </div>
        </div>
        <div className="col">
          <div className="tableOrderHeader">
            <div className="mImg">
              <a href="#!">
                <img src={logo} style={{ maxHeight: '50px' }} alt="..." />
              </a>
            </div>
            <div className="tblCounter">
              <span>
                <span>
                  <img src={tableIcon} alt="..." />
                </span>
                <span className="ps-2">{tableName}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopNavbar;
