import React from 'react';
import Scrollspy from 'react-scrollspy';
import DishCard from '../menu/DishCard';

const InfiniteScroll = ({
  data = [],
  activeCuisineId,
  setActiveCuisineId,
  categories,
  setActiveCategoryId
}) => {
  return (
    <>
      <div
        style={{ height: categories.length > 0 ? '75vh' : '80vh' }}
        className="overflow-scroll"
        id="scrollableContent"
      >
        {data?.map(cuisine => (
          <section
            key={cuisine.cuisine_id}
            id={`cuisine_${cuisine.cuisine_id}`}
            data-cuisine-id={cuisine.cuisine_id}
            className="pt-1"
          >
            <h4 className="mt-2">
              <b>{cuisine.cuisine_name}</b>
            </h4>
            <hr className="my-1" />
            {cuisine.dish_category.length > 0 ? (
              cuisine.dish_category.map(category => (
                <section
                  id={`category_${category.id}`}
                  data-category-id={category.id}
                  key={category.id}
                  className="pt-1"
                >
                  <h5 className="mt-2 text-bold"># {category.name}</h5>
                  {category?.dishes?.length > 0 ? (
                    category?.dishes?.map((dish, key) => (
                      <DishCard dish={dish} key={key} />
                    ))
                  ) : (
                    <div className="text-center py-5">{`No items found in ${category.name}`}</div>
                  )}
                </section>
              ))
            ) : (
              <>
                {cuisine.dishes.length > 0 ? (
                  cuisine.dishes.map((dish, key) => (
                    <DishCard dish={dish} key={key} />
                  ))
                ) : (
                  <div className="text-center py-5">{`No items found in ${cuisine.cuisine_name}`}</div>
                )}
              </>
            )}
          </section>
        ))}
      </div>
      {/* Main Scroll Spy Code */}
      <Scrollspy
        className="d-none"
        currentClassName="active"
        rootEl="#scrollableContent"
        items={data?.map(cuisine => `cuisine_${cuisine.cuisine_id}`)}
        onUpdate={item => {
          if (item) {
            setActiveCuisineId(+item.getAttribute('data-cuisine-id'));
          }
        }}
      >
        {data?.map(cuisine => (
          <div data-cuisine-id={cuisine.cuisine_id} key={cuisine.cuisine_id}>
            {cuisine.cuisine_name}
            {categories.length > 0 &&
            +cuisine.cuisine_id === activeCuisineId ? (
              <Scrollspy
                items={categories.map(category => `category_${category.id}`)}
                currentClassName="active"
                onUpdate={item => {
                  if (item) {
                    setActiveCategoryId(+item.getAttribute('data-category-id'));
                  }
                }}
                rootEl="#scrollableContent"
              >
                {categories.map(category => (
                  <a
                    key={category.id}
                    data-category-id={category.id}
                    href={`#category_${category.id}`}
                  >
                    - {category.name}
                  </a>
                ))}
              </Scrollspy>
            ) : (
              <></>
            )}
          </div>
        ))}
      </Scrollspy>
    </>
  );
};

export default InfiniteScroll;
