import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import TopNavbar from '../components/TopNavbar';
import useUrl from '../hooks/useUrl';

const PaymentFailed = () => {
  const url = useUrl();
  const [redirectingIn, setRedirectingIn] = useState(5);
  const orderId = url.get('order_id');
  const navigate = useNavigate();

  useEffect(() => {
    if (redirectingIn === 0) {
      navigate('/menu');
      return;
    }

    const intervalId = setInterval(() => {
      setRedirectingIn(redirectingIn - 1);
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [navigate, redirectingIn]);

  return (
    <>
      <div className="my-3">
        <TopNavbar />
      </div>
      <div className="vh-80">
        <div className="d-flex justify-content-center align-items-center h-100">
          <div className="text-center">
            <h4 className="fw-bolder">☹️ Payment Cancelled</h4>
            {orderId ? <p>Order Id: #{orderId}</p> : null}
            <Link className="btn mt-2 btnPrimary" to={`/`}>
              Go to Dashboard
            </Link>
            <p>Redirecting in {redirectingIn} seconds...</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentFailed;
