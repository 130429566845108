import React from 'react';
import { Spinner } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { banngerImg, checkIcon1, img1 } from '../assets/images/_index';
import Api from '../helpers/Api';
import TopNavbar from './../components/TopNavbar';
import MyOrderDetails from './MyOrderDetails';

const MyOrders = () => {
  const { data, isLoading, refetch } = useQuery('my-orders', () =>
    Api.get('/orders/listing')
  );

  return (
    <section>
      <div className="pageTopCol headerSection">
        <TopNavbar />
      </div>
      <div className="bannerCol spaceTopCol">
        <img src={banngerImg} alt="..." />
      </div>
      <div className="cardSection">
        <div className="container">
          <div className="orderMainCol">
            <div className="titleCol">
              <h4 className="mdTitle">My Orders</h4>
            </div>
            <div className="pOptions">
              <div className="paymentProOption">
                {data?.data?.length > 0 ? (
                  data?.data?.map((order, i) => (
                    <div className="row g-2 my-2" key={i}>
                      <div className="col-auto">
                        <MyOrderDetails order={order} refetchOrders={refetch}>
                          <div className="smProImgCol cursor-pointer">
                            <img src={img1} alt="..." className="sProImg" />
                          </div>
                        </MyOrderDetails>
                      </div>
                      <div className="col">
                        <div className="row">
                          <div className="col">
                            <MyOrderDetails
                              order={order}
                              refetchOrders={refetch}
                            >
                              <span className="pProTitle cursor-pointer">
                                Table - {order?.table?.name}, Order ID:{' '}
                                {order?.order_id}
                              </span>
                            </MyOrderDetails>
                          </div>
                          <div className="col-auto">
                            <span className="pPrice">${order?.amount}</span>
                          </div>
                        </div>
                        <div className="pProBtmRow">
                          <div className="row align-items-center g-2">
                            <div className="col">
                              <div className="qtyCol2">
                                <div className="row align-items-center g-2">
                                  <div className="col-auto">
                                    {order?.created_at}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-auto">
                              <div className="orderCheck">
                                <span>
                                  <img
                                    src={checkIcon1}
                                    alt="..."
                                    className="orderCompleted"
                                  />
                                </span>
                                <span>{order?.status}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <>
                    {isLoading ? (
                      <Spinner size="sm" />
                    ) : (
                      <p>No orders found!</p>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MyOrders;
