import React, { useState } from 'react';
import Dashboard from './Dashboard';
import ScanQR from './ScanQR';

const Homepage = () => {
  const [authToken, setAuthToken] = useState(
    localStorage.getItem('tableOrderingAuthToken')
  );

  const recheckAuthToken = () =>
    setAuthToken(localStorage.getItem('tableOrderingAuthToken'));

  return (
    <>
      {authToken ? (
        <Dashboard recheckAuthToken={recheckAuthToken} />
      ) : (
        <ScanQR />
      )}
    </>
  );
};

export default Homepage;
