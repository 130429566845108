import React from 'react';
import { Link } from 'react-router-dom';
import {
  banngerImg,
  bgShape,
  logoutIcon,
  plusIconWhite,
  spoonIcon,
  userIcon
} from '../assets/images/_index';
import ChooseOrderStateModal from '../components/dashboard/ChooseOrderStateModal';
import TopNavbar from '../components/TopNavbar';

const Dashboard = ({ recheckAuthToken = () => {} }) => {
  return (
    <section>
      <div className="pageTopCol headerSection">
        <TopNavbar />
      </div>
      <div className="bannerCol spaceTopCol">
        <img src={banngerImg} alt="..." />
      </div>
      <div className="cardSection">
        <div className="container">
          <div className="cardInnerCol">
            <div className="row">
              <div className="col-6">
                <ChooseOrderStateModal>
                  <div className="cardStyle">
                    <div className="cardStyleIcon">
                      <img src={spoonIcon} alt="..." />
                    </div>
                    <div className="cardBtn">
                      <div className="btn btnPrimary iconBtn">
                        <span>
                          <img src={plusIconWhite} alt="..." />
                        </span>
                        <span>New Order</span>
                      </div>
                    </div>
                  </div>
                </ChooseOrderStateModal>
              </div>
              <div className="col-6">
                <div className="cardStyle">
                  <Link to="/my-orders">
                    <div className="cardStyleIcon">
                      <img src={userIcon} alt="..." />
                    </div>
                    <div className="cardBtn">
                      <div className="btn btnPrimary iconBtn">
                        <span>My Orders</span>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footerCol">
        <div className="logouBotmCol">
          <div className="logoutLink">
            <button
              className="border-0 bg-transparent"
              onClick={() => {
                localStorage.removeItem('tableOrderingAuthToken');
                localStorage.removeItem('tableId');
                localStorage.removeItem('tableName');
                localStorage.removeItem('tableOrderingLogo');
                localStorage.removeItem('tableOrderingCartId');
                recheckAuthToken();
              }}
            >
              <span className="logoutText">Logout</span>
              <span>
                <img src={logoutIcon} alt="..." />
              </span>
            </button>
          </div>
          <div className="bannerShape">
            <img src={bgShape} alt="..." />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Dashboard;
