import React from 'react';
import { ToastContainer } from 'react-toastify';
import { QueryClient, QueryClientProvider } from 'react-query';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.min.css';
import './App.css';
import './assets/css/bootstrap.min.css';
import './assets/css/style.css';
import AppProvider from './contexts/AppContext';
import routes from './routes/routes';
const router = createBrowserRouter(routes);

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <AppProvider>
        <RouterProvider router={router}></RouterProvider>
        <ToastContainer limit={1} autoClose={1000} />
      </AppProvider>
    </QueryClientProvider>
  );
};

export default App;
