import React, { useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useMutation } from 'react-query';
import {
  badgeIcon,
  foodIcon,
  img1,
  nonVegIcon,
  plusIconWhite,
  priceIcon,
  soldIcon,
  vegIcon
} from '../../assets/images/_index';
import { AppContext } from '../../contexts/AppContext';
import Api from '../../helpers/Api';
import { DishPrefList } from '../../helpers/Constants';
import useCart from '../../hooks/useCart';
import MenuItem from '../../pages/MenuItem';

const DishCard = ({ dish }) => {
  const { refetch } = useCart();
  const appContext = useContext(AppContext);
  const { updateAppContext, cart, tableId } = appContext;

  const AddToCartMutation = useMutation(
    formData => Api.post('/carts/create-cart', formData),
    {
      onSuccess: json => {
        if (json?.data?.id) {
          updateAppContext({
            ...appContext,
            cart: { ...cart, id: json.data.id }
          });
          localStorage.setItem('tableOrderingCartId', json.data.id);
        }
        if (cart.id) {
          refetch();
        }
      }
    }
  );

  const AddToCart = () => {
    let formData = new FormData();
    formData.append('product_id', dish?.product_id);
    formData.append('quantity', 1);
    if (cart.id) formData.append('order_id', cart.id);
    if (tableId) formData.append('table_id', tableId);
    AddToCartMutation.mutate(formData);
  };

  // price to show in listing of items or dishes
  const price = +dish?.is_multi_size_enabled // checking if dish is enabled for multi size
    ? dish?.product_details_with_sizes[0].price // if multisize found, then showing price of first size available for the dish
    : dish?.product_details_without_size?.size_price || // if dish is single size, then showing that price
      0; // NOTE: using 0 as default here to cover up the issue of wrong configured dish (eg: price was being returned as '' empty string)

  const customizable =
    +dish?.is_multi_size_enabled ||
    dish?.product_details_without_size?.default_ingredients?.length > 0 ||
    dish?.product_details_without_size?.add_on_ingredients?.length > 0 ||
    dish?.product_details_without_size?.base_one_ingredients?.length > 0 ||
    dish?.product_details_without_size?.base_two_ingredients?.length > 0 ||
    dish?.product_details_without_size?.base_three_ingredients?.length > 0;

  const { addedItems } = useCart();
  const itemIsInCart = addedItems.find(
    item => +item.item_id === +dish?.product_id
  );
  return (
    <div
      className={`cardStyle1 mb-1 ${itemIsInCart ? 'active' : ''}`}
      key={dish?.product_id}
    >
      <div className="row g-2 align-items-center">
        <div className="col-auto">
          <div className="proImgCol">
            <div className="cs1ImgCol">
              <MenuItem AddToCartMutation={AddToCartMutation} item={dish}>
                <img
                  className="cursor-pointer"
                  src={dish?.product_img || img1}
                  alt="..."
                />
              </MenuItem>
            </div>
            <div className="proBtmBtnCol">
              {customizable ? (
                <MenuItem AddToCartMutation={AddToCartMutation} item={dish}>
                  <button className="btn iconBtn btnPrimary">Custom</button>
                </MenuItem>
              ) : (
                <button className="btn iconBtn btnPrimary" onClick={AddToCart}>
                  {AddToCartMutation.isLoading ? (
                    <div className="d-flex align-items-center justify-content-center">
                      Adding...
                    </div>
                  ) : (
                    <>
                      <span>
                        <img src={plusIconWhite} alt="..." />
                      </span>{' '}
                      <span>Add</span>
                    </>
                  )}
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="col">
          <div className="cs1ContentCol">
            <Row className="g-0">
              <Col>
                <MenuItem AddToCartMutation={AddToCartMutation} item={dish}>
                  <h2 className="cardProtitle">{dish?.product_name}</h2>
                </MenuItem>
              </Col>
              <Col xs="auto">
                <div className="vegIcon">
                  <img
                    src={dish?.veg ? vegIcon : nonVegIcon}
                    width="25"
                    alt="..."
                  />
                </div>
              </Col>
            </Row>
            <div className="orderSelectMainCol">
              <div className="d-flex align-items-center justify-content-between">
                <div className="proOptionsList">
                  <ul>
                    {Object.keys(DishPrefList).map(allergen => (
                      <li
                        className={`me-1 ${
                          dish?.allergens
                            ?.map(alrgn => alrgn.value)
                            ?.includes(allergen)
                            ? 'activeOption'
                            : ''
                        }`}
                        key={allergen}
                      >
                        <img alt="..." src={DishPrefList[allergen]} />
                      </li>
                    ))}
                  </ul>
                </div>
                {/* <div className="orderCustomIcon">
                  <ul>
                    <li>
                      <img src={soldIcon} alt="..." />
                    </li>
                    <li>
                      <img src={priceIcon} alt="..." />
                    </li>
                    <li>
                      <img src={foodIcon} alt="..." />
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
            <div className="proPrice">
              <span className="priceSymbol">$</span> {price}
            </div>
          </div>
        </div>
      </div>
      {itemIsInCart ? (
        <div className="addItemsInd">
          <span>{itemIsInCart?.item_quantity} Added</span>
        </div>
      ) : null}
    </div>
  );
};

export default DishCard;
