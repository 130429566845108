import React, { useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { angleLeftDark, angleRightDark } from '../../assets/images/_index';
import 'swiper/css/bundle';
import 'swiper/swiper-bundle.min.css';

const SwiperMenu = ({
  activeCuisineId,
  data,
  categories,
  activeCategoryId,
  setActiveCategoryId,
  setActiveCuisineId
}) => {
  const swiperRef = useRef();

  useEffect(() => {
    const swiper = swiperRef.current;
    if (swiper) {
      swiper.slideTo(
        data?.findIndex((cuisine, i) =>
          +cuisine.cuisine_id === activeCuisineId ? i : 0
        ) || 0
      );
    }
    return () => {};
  }, [activeCuisineId, swiperRef, data]);

  return (
    <div className="topLinksCol p-0 bg-white">
      <div className="d-flex pb-1 justify-content-between align-items-center">
        <div
          onClick={() => swiperRef.current?.slidePrev()}
          className={'cursor-pointer text-center'}
          style={{ minWidth: '30px' }}
        >
          <img src={angleLeftDark} alt="" />
        </div>
        <Swiper
          onBeforeInit={swiper => {
            swiperRef.current = swiper;
          }}
          className="topLinksSwiper py-2 swiperArrowStyle"
          spaceBetween={30}
          slidesPerView={'auto'}
        >
          {data?.map((cuisine, cuisineKey) => {
            return (
              <SwiperSlide key={cuisine.cuisine_id} className="ps-1">
                <a
                  href={`#cuisine_${cuisine.cuisine_id}`}
                  className={`topLink border-0 bg-transparent text-nowrap ${
                    +cuisine.cuisine_id === activeCuisineId ||
                    categories.find(
                      category => +category.id === activeCategoryId
                    )?.length > 0
                      ? 'active'
                      : ''
                  }`}
                  onClick={() => {
                    setActiveCuisineId(+cuisine.cuisine_id);
                    if (cuisine.dish_category.length > 0) {
                      setActiveCategoryId(+cuisine.dish_category[0].id);
                    }
                  }}
                  key={cuisine.cuisine_id}
                >
                  {cuisine.cuisine_name}
                </a>
              </SwiperSlide>
            );
          })}
        </Swiper>
        <div
          onClick={() => swiperRef.current?.slideNext()}
          className={'cursor-pointer text-center'}
          style={{ minWidth: '30px' }}
        >
          <img src={angleRightDark} alt="" />
        </div>
      </div>
    </div>
  );
};

export default SwiperMenu;
