import React from 'react';

const SizeSelector = ({
  items,
  title,
  selectedItem,
  update,
  accessor,
  disabled
}) => {
  const Item = ({ item }) => {
    const selected = selectedItem === item.id;
    return (
      <div className="radio">
        <input
          id={`radio-size-${item.id}`}
          value={item.id}
          checked={selected}
          type="radio"
          name="size"
          onChange={e => {
            update(+e.target.value);
          }}
          disabled={disabled}
        />
        <label htmlFor={`radio-size-${item.id}`} className="radio-label">
          {item[accessor]}
          <span className="fw-bold "> (${item?.price})</span>
        </label>
      </div>
    );
  };

  return (
    <div className="proDetailMainCol mt-3">
      <div className="proDetailTitle">
        <h4 className="mdTitle lightColor mb-3 ">{title}</h4>
      </div>
      <div className="proDetailList sizeSelector">
        {items.map((item, key) => (
          <Item item={item} key={key} />
        ))}
      </div>
    </div>
  );
};

export default SizeSelector;
