import React from 'react';
import { proLgImg } from '../../assets/images/_index';

const HeroImage = ({ image }) => {
  return (
    <div className="sProImgCol">
      <img src={image || proLgImg} alt="..." className="sProLgImg" />
    </div>
  );
};

export default HeroImage;
